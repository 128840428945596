import erickIcon from '../assets/e-rick.svg';
import ebikeIcon from '../assets/E-Bike1.svg';
import eloaderIcon from '../assets/loader-final 1.svg';
import piaggioIcon from '../assets/P-logo.svg';
import diamondIcon from '../assets/logo-jpg.svg';
import { brandFilterDataType, brandType, categoryFilterDataType, categoryType, filterValueType } from './Types';

const environment = "production";

export const baseUrl: any = {
    development: "https://api.lms.embifi.in/api/v1",
    production: "https://prod.api.lms.embifi.in/api/v1",
    local: "http://localhost:4002/api/v1"
};

export const BASE_URL: string = baseUrl[environment];

// export const Category: categoryType = {
//     E_RICK: 'ERICK',
//     E_LOADER: 'ELOADER',
//     E_SCOOTY: 'ESCOOTY'
// };

export const Brand: brandType = {
    PIAGGIO: 'PIAGGIO',
    DIAMOND: 'DIAMOND',
};

// export const CategoryFilterData: categoryFilterDataType = {
//     erick: { label: 'E-RICK', icon: erickIcon, value: Category.E_RICK },
//     escooty: { label: 'E-SCOOTY', icon: ebikeIcon, value: Category.E_SCOOTY },
//     eloader: { label: 'E-LOADER', icon: eloaderIcon, value: Category.E_LOADER },
// }

export const BrandFilterData: brandFilterDataType = {
    piaggio: { label: 'PIAGGIO', icon: piaggioIcon, value: Brand.PIAGGIO },
    diamond: { label: 'DIAMOND', icon: diamondIcon, value: Brand.DIAMOND },
}

export const FilterValue: filterValueType = {
    brand: 'BRAND',
    category: 'CATEGORY',
}