import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "96%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
     borderRadius:"15px"
};

function CustomerFormModel() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen} style={{color:"#000"}}>Are you a Customer</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ display:"flex", flexDirection:"column"}}>
                    <label htmlFor="">Name</label> <br />
                    <TextField
                        id="outlined-size-small"
                        size="small"
                    />
                    <br />
                    <label>Phone Number</label>
                    <br />
                    <TextField

                        id="outlined-size-small"

                        size="small"
                    />

                    <br />
                 
                  
                    <Button style={{background:"#4A2DFF", color:"#fff", padding:""}}>Send Request</Button>





                </Box>

            </Modal>
        </div>
    );
}

export default CustomerFormModel;