import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Product {
  _id: string;
  category: string;
  resell_price: number;
  quantity: number;
  images: { key: string }[];
}

interface CartState {
  products: Product[];
}

const initialState: CartState = {
  products: []
};
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<Product>) => {
      const product = action.payload;
      const existingProduct = state.products.find(p => p._id === product._id);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        state.products.push({ ...product, quantity: 1 });
      }
    },
    removeFromCart: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      const existingProduct = state.products.find(p => p._id === productId);
      if (existingProduct) {
        existingProduct.quantity -= 1;
        if (existingProduct.quantity <= 0) {
          state.products = state.products.filter(p => p._id !== productId);
        }
      }
    },
    clearItem: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      state.products = state.products.filter(p => p._id !== productId);
    },
    clearCart: (state) => {
      state.products = [];
    }
  }
});

export const { addToCart, removeFromCart, clearItem, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
