import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BrowserRouter } from "react-router-dom";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/style/index.scss';

import { ThemeProvider } from "@material-tailwind/react";
import { Provider } from 'react-redux';
import store from './Redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ThemeProvider>
      <Provider store={store}>
      <App />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);
