import lottieTree from "../../assets/tree-lottie.svg";
import handTree from "../../assets/Hand-Tree.svg";
// import heroTitle from "../../assets/Hero_TiTle.svg";
import AnimateLottie from "../../LottieAnimation/Lottie";

export default function HeroSecondary() {
  return (
    <div className="hero-container">
      {/* <div className="hero-child-1">
        <AnimateLottie />
      </div> */}
        {/* <div className="hero-child-2">
        <img src={handTree}  className="leaveImage"/>
      </div> */}
      <div className="hero-child-2 mt-24
      ">
        Power up your ride, protect <br />
        the Earth with electric pride
      </div>
      <div className="hero-child-3">
        <img src={handTree}  className="leafImage"/>
      </div>
    </div>
  );
}
