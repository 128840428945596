
import axios from 'axios'
import { BASE_URL } from '../Constants/General';
import { error } from 'console';

// const baseURL = BASE_URL;
// const baseURL2 = "https://api.products.embifi.in/api";
const baseURL2 = "http://localhost:4002/api";
// const baseURL2 ="https://api.lms.embifi.in/api";
// const baseURL2="https://prod.api.lms.embifi.in/api/v1"

// axios.defaults.baseURL = "https://api.products.embifi.in"
// axios.defaults.baseURL = "https://api.products.embifi.in/ev/v1/get-products"

const API = axios.create({
  // baseURL,

  withCredentials: true,
  // headers: {
  //   'lms-crypto-token': 'asdkfjk12!@#$%^&456SDFGHJqwe!@```)(&^%$(*&^%$ASDF',
  //   'application': 'CRE',
  // },
});

API.interceptors.request.use(
  async config => {
    if (config.url?.includes('/v1/common/view?key')) {
      config.headers['application'] = 'CRE';
      config.headers['lms-crypto-token'] = 'asdkfjk12!@#$%^&*456SDFGHJqwe!@```)(*&^%$(*&^%$ASDF';
    }
    return config;
  }, error => {

  }
)


//  export const fetchImage = async (key: string, { base64 = true, signedUrl = false }) => await axios.get(baseURL2 + `/common/v1/ev-images?key=${key}&signedUrl=${signedUrl}&base64Required=${base64}`);
//  export const fetchImage =  (key: string ) =>  axios.get(baseURL2 + `http://localhost:4002/api/common/view?key=vehicle_confiscated_docs/vehicle_photo_from_front/660d018a3112703bd952c2cd&base64Required=true`)
export const fetchImage = (key: string , { base64 = true }) => API.get(BASE_URL + `/common/view?key=${key}&base64Required=${base64}`);




export const fetchData = () => axios.get(BASE_URL + "/product/get-products");
// export const fetchCategory = (filter: string, value: string, skip: number, limit: number) => API.get(baseURL2 + `/category/v1/get-category/?filter=${filter}&value=${value}&skip=${skip}&limit=${limit}`)
// export const fetchFilterValues = () => API.get(baseURL2 + `/category/v1/get-filter-list`)
