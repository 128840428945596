/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useEffect } from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import Range from "../../assets/arrow_range.svg";
import ElectricService from "../../assets/electrical_services.svg";
import { AddCard } from "@mui/icons-material";
import ElectricBike from "../../assets/Frame 34.svg";
import ElectricBike2 from "../../assets/Frame 35.svg";
import call from "../../assets/call.svg";
import Escooter from "../../assets/image.svg";
import ProductCard from "./ProductCard";
import { fetchImage } from "../../Api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faXmark } from "@fortawesome/free-solid-svg-icons";

import "../../css/Card.css";
import { AnyARecord } from "dns";
import { Console, log } from "console";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

const imageObj = {};

export default function Card({ val, callBack, onRemoveClick, onRemoveClosed, data1 ,handleClose}: any) {
  const [card, setCard] = useState<any>(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isClosed, setIsClosed] = useState(false);
  const [urls, setUrls] = useState<string[]>([]);
  const getImage = async (fileLink: string) => {
    if (!fileLink) {
      console.error("Invalid file link provided");
      return;
    }
  
    try {
      const url = new URL(fileLink);
      const key = url.searchParams.get("key");
  
      if (!key) {
        console.error("Key not found in URL");
        return;
      }  
      let { data } = await fetchImage(key as string, {
        base64: true,
      });
      return data?.base64 || "";
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };
  
  // useEffect(() => {
  //   const loadImages = async () => {
  //     let imagesToLoad = data1?.images?.length > 0 ? data1.images : data1?.vehicle_images;
      
  //     if (imagesToLoad?.length > 0) {
  //       const imageUrls = await Promise.all(imagesToLoad.slice(0, 3).map((image: string) => getImage(image)));
  //       setUrls(imageUrls.filter(url => url));
  //     }
  //   };

  //   loadImages();
  // }, [data1]);
  useEffect(() => {
    const loadImages = async () => {
      // Use only images that are not videos
      let imagesToLoad = (data1?.images?.length > 0 ? data1.images : data1?.vehicle_images) || [];
      const imageOnlyProducts = imagesToLoad.filter(
        (url: string) => !url.includes("data:video/mp4") && !url.endsWith(".mp4")
      );

      if (imageOnlyProducts.length > 0) {
        const imageUrls = await Promise.all(
          imageOnlyProducts.slice(0, 3).map((image: string) => getImage(image))
        );
        setUrls(imageUrls.filter((url) => url));
      }
    };

    loadImages();
  }, [data1]);


  const handleClose2 = () => {
    onRemoveClosed();
  };

  const handleClick = () => {
    // console.log("click")
  };

  useEffect(() => {
    console.log(callBack);
  }, []);
  const handleClose1= ()=>{
    // console.log("click");
    handleClose();
    
  }
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const openImageModal = (url: string) => {
    setSelectedImage(url);
    setModalOpen(true);
  };
  

  return (
    <>
      <div className={`container-prod1`} 
      // onClick={handleClose}
      >
        <div className="card1" >
          {/* <button onClick={handleClose1}> */}
          <FontAwesomeIcon
              icon={faXmark}
              className="crossbtn"
              // style={{height:"38px", width:"38px",marginRight:"2rem"
              //   ,marginTop:"1.8rem"
              // }}
              onClick={handleClose1}
            />
          {/* </button> */}
       
          <div className="imgBx1" >
            <img src={urls[0]} alt="e-rick img" style={{width:"35%", height:"35%" ,borderRadius:"0.5em" ,objectFit:"contain"}} onClick={() => openImageModal(urls[0])}/>
          </div>
          <div className="contentBx1">
            <h2>{data1.category}</h2>
            <div className="size1">
              <b></b>
            </div>
             <div style={{ display: "flex", justifyContent: "center" }}>
              <div
               className="size1"
                style={{height: "auto",width: "auto",color: "black",display: "flex",flexDirection: "column",alignItems: "flex-start",}}
                >
                  {data1?.agent_name && <h6>Agent Name: {data1?.agent_name}</h6>}
                {data1?.agent_contact&&  <h6>Agent Contact: {data1?.agent_contact}</h6>}
                  {data1?.location&&  <h6>Vehicle Location: {data1?.location}</h6> }
                </div>
                </div>

{/* 
            <div
              className="color1 mt-10"
              style={{
                color: "gray",
                fontSize: 10,
              }}
            >
              <div className="px-2">
                <p>
                  <SpeedIcon />
                   <FontAwesomeIcon icon={faXmark} /> 
                </p>
                Top speed
                <p
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontWeight: 500,
                  }}
                >
                  {val?.top_speed}
                </p>
              </div>

              <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                <p>
                  <BatteryChargingFullIcon />
                </p>
                Battery
                <p
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontWeight: 500,
                  }}
                >
                  {val?.batter}
                </p>
              </div>

              <div>
                <p>
                  <img src={Range} alt="" />
                </p>
                Range
                <p
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontWeight: 500,
                  }}
                >
                  {val?.top_speed}
                </p>
              </div>

              Electric Service

              <div className="px-3">
                <p>
                  <img src={ElectricService} alt="" />
                </p>
                Charging Time
                <p
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontWeight: 500,
                  }}
                >
                  {val?.top_speed}
                </p>
              </div>￼
            </div> */}

            <div className="Card-footer1" style={{ display: "flex", overflowX: "auto", gap: "10px" }}>
              {/* <img src={urls[0]} alt="" style={{width:"80px", height:"80px", border:"2px solid #C8FFA4", borderRadius:"0.5em"}} />
              <img src={urls[1]} alt="" style={{width:"80px", height:"80px", border:"2px solid #C8FFA4", borderRadius:"0.5em"}} />
              <img src={urls[2]} alt="" style={{width:"80px", height:"80px", border:"2px solid #C8FFA4", borderRadius:"0.5em"}} /> */}
               {urls.map((url, index) => (
              <img key={index} src={url} alt="" style={{ width: "80px", height: "80px", border: "2px solid #C8FFA4", borderRadius: "0.5em" ,objectFit:"contain"}}  onClick={() => openImageModal(url)}/>
            ))}
              <a onClick={handleClose}>Request to Call Back</a>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && selectedImage && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Selected"
              onClick={handleCloseModal}
            />
          </div>
        </div>
      )}
    </>
  );
};
