import sideLeaf from "../../assets/side-leave.svg";
import Carousel3D from "../../Components/UI/Carousel3D";

export default function HeroHead() {
  return (
    <div className="herohead-container my-40">
      {/* <div className="hh-child-1">
        <img src={sideLeaf} style={{ fontSize: "50px" }} />
      </div> */}
      <div className="middle-text">
        <p
          className="hero-text "
          style={{ color: "green" }}
        >
          EV KA
        </p>
        <p className="hero-text Hero-Heading" >
          BAZAAR
        </p>
      </div>

      <div className="hh-child-2">
        <Carousel3D />
      </div>
    </div>
  );
}