import React, { useEffect, useState } from 'react';
import '../css/Cart.css';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, removeFromCart, clearItem, clearCart } from '../Redux/cartSlice';
import { RootState } from '../Redux/store';
import defaultImage from '../assets/e-bike2.svg';
import { log } from 'console';

interface Product {
  _id: string;
  category: string;
  resell_price: number;
  quantity: number;
  images: { key: string }[];
}

const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.cart.products);

  const handleAdd = (product: Product) => {
    dispatch(addToCart(product));
  };

  const handleRemove = (productId: string) => {
    dispatch(removeFromCart(productId));
  };

  const handleClearItem = (productId: string) => {
    dispatch(clearItem(productId));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };  
  return (
    <div className="cart p-4">
      {cart.length === 0 ? (
        <h1 className="text-center">Your Cart is Empty</h1>
      ) : (
        <>
          <table className="divide-gray-200" style={{ border: "1px solid black", width: "100%", margin: "0" }}>
            <thead>
              <tr style={{ backgroundColor: "grey" }}>
                <th>Image</th>
                <th>Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((product) => (
                <tr key={product._id}>
                  <td>
                    <ProductImage imageKey={product.images[0]?.key || defaultImage} />
                  </td>
                  <td>{product.category}</td>
                  <td>₹{product.resell_price || "NA"}</td>
                  <td>
                    <button onClick={() => handleAdd(product)} className="px-2 py-1" style={{ borderRadius: "50%" }}>+</button>
                    {product.quantity}
                    <button onClick={() => handleRemove(product._id)} className="px-2 py-1" style={{ borderRadius: "50%" }}>-</button>
                  </td>
                  <td>₹{product.resell_price * product.quantity}</td>
                  <td>
                    <button onClick={() => handleClearItem(product._id)} className="px-2 py-1" style={{ borderRadius: "100%", fontWeight: "bolder", color: "red" }}>×</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-4">
            <button 
              onClick={handleClearCart} 
              className="px-4 py-2 mt-2 clear-btn"
            >
              Clear Cart
            </button>
          </div>
        </>
      )}
    </div>
  );
};

interface ProductImageProps {
  imageKey: string;
}

const ProductImage: React.FC<ProductImageProps> = ({ imageKey }) => {
  const [url, setUrl] = useState(defaultImage);  
  useEffect(() => {
    const getImage = async () => {
      try {
        const response = await fetch(`https://api.common-wrapper.embifi.in/v1/cloud/retrieve?bucket=lms-prod-v1&key=${imageKey}`);
        const data = await response.json();
         console.log(data);
         
        if (data.status && data.base64) {
          setUrl(data.base64);
        } else {
          console.error("Failed to fetch image: ", data.message);
        }
      } catch (error) {
        console.error("Failed to fetch image", error);
      }
    };
    if (imageKey) {
      getImage();
    }
  }, [imageKey]);

  return <img src={url} alt="Product" width={50} />;
};

export default Cart;
