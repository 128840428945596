import { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import { BrandCategoryPage } from '../../Pages';

import Card from './Card';
import { Fade, Backdrop, Modal, Box } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
  borderRadius: 2
};

export default function ProductInfoModal({ isOpen, onPress, data }) {
  const [open, setOpen] = useState(true);
  const [url, setUrl] = useState("");
  //   const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onPress}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <>
          <Fade in={isOpen}>
            <Box sx={style}>
              <Card data1 = {data} handleClose={onPress}/>
            </Box>
          </Fade>
        </>
      </Modal>
    </div>
  );
}