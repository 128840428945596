import Navbar from './Components/UI/Navbar';
import AppRoutes from './Routes/Routes';


// import custum css
import './App.css';
import './css/custom-classes.css';
import './assets/style/ProductCard.scss'


function App() {
 
  return (
    <div className="App">
      <Navbar/>
      <AppRoutes/>
    </div>
  );
}

export default App;
