import { useEffect, useState } from "react";
import { fetchImage } from "../../Api";
import { fcINR } from "../../utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SpeedIcon from "@mui/icons-material/Speed";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import Range from "../../assets/arrow_range.svg";
import ElectricService from "../../assets/electrical_services.svg";
import defaultImage from "../../assets/e-bike2.svg";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { addToCart,removeFromCart } from "../../Redux/cartSlice";
import { useDispatch,useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { CommentsDisabledOutlined } from "@mui/icons-material";
import { Console, log } from "console";
import { truncate } from "fs";
import Card from "./Card";

const ProductCard = ({ val, keyNumber, onPress, onClick ,isactive }: any) => {
  

  // console.log(image)

      //  console.log("val",isactive)

  const dispatch = useDispatch();
  const cartProduct = useSelector((state: RootState) =>
    state.cart.products.find((product) => product._id === val._id) 
  );
  // console.log(cartProduct);
  
  const quantity = cartProduct ? cartProduct.quantity : 0;
   
   
  const [ind, setInd] = useState<any>(null);

  const [url, setUrl] = useState("");
  const moreInfo = (keyNumber: Number) => {
    setInd(keyNumber);
  };

  
  // const [showCard, setShowCard] = useState(false); 

  // useEffect(() => {
  //   console.log(val?.UrlImage);
  // }, [val]);
  // console.log("Image",val?.images[0])
  // 
//   const getImage = async (fileLink:any) => {
// // console.log(fileLink);
// const url = new URL(fileLink);
// const key = url.searchParams.get("key");
//     //  console.log(key);
    
//     try {
//       let { data } = await fetchImage(key as string ,{
//         base64:true
//       });
        
//       console.log("data",data);
//           // setUrl(data.base64);
//         setUrl(data?.base64)   

//     } catch (error) {
//       // console.log('error',error);
//       return null;
      
//     }
//   }; 

const getImage = async (fileLink: any) => {
  if (!fileLink) {
    console.error("Invalid file link provided");
    return;
  }

  try {
    const url = new URL(fileLink);
    const key = url.searchParams.get("key");

    if (!key) {
      console.error("Key not found in URL");
      return;
    }

    let { data } = await fetchImage(key as string, {
      base64: true,
    });

    // console.log("data", data);
    setUrl(data?.base64);
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
};

  // console.log("img",setUrl)     
  const handleRemoveClick = () => {
    setInd(null);
  };

  const handleRemoveClosed = () => {
    setInd(null);
  };

  // useEffect(() => {
  //   // console.log(val);

  //   if (val?.vehicle_images?.length > 0 && val.vehicle_images[0]) {
  //     getImage(val.vehicle_images[0]);
  //   } else  {
  //     getImage(val?.images[0]);
  //   }
  // }, [val]);

  useEffect(() => {
    // console.log(val);

    // if (val?.images?.length > 0 && val?.images[0]) {
    //   if(!val?.images[0]?.includes("data:video/mp4")){
    //     getImage(val?.images[0]);
    //   }else{
    //     if(val?.images[1]){
    //       getImage(val?.images[1]);
    //     } 
    

    //   }
      
     
    // } 

    const image = val?.images?.length > 0 && val?.images[0] 
    ? !val?.images[0].includes("data:video/mp4") 
      ? getImage(val?.images[0]) 
      : val?.images[1] && getImage(val?.images[1])
    : null;
  // console.log("location",val.location );
    
  //  console.log("id",val._id);
     
    
    // else  {
    //   getImage(val.vehicle_images[0]);
    // }
  }, [val]);
  // useEffect(() => {
  //   // console.log(val);


  //     getImage(val?.images[0]);
    
  // }, [val]);

  
  const handleAddbtn = () => {
    dispatch(addToCart(val));
  };
  
  const handleRemovebtn = () => {
    if (quantity > 0) {
      dispatch(removeFromCart(val._id));
    }
  };
   
   
  // const handleCardVisibility = () => {
  //   setShowCard(!showCard); 
  // };
  
  return (
    
     <>
       {/* <ProductInfoModal/> */}
   {url ?  (
    
       <div className={isactive?`container-prod`:`container-prod2`}>
        
         <div className={isactive ? "card":"card2"}>
          <FontAwesomeIcon icon={faXmark} />
           <div className="imgBx"  
           // style={{height:"100%",width:"100%",objectFit:"cover" }}
           >
             <img
             src={url || defaultImage}
            
             alt=""
              style={{ width: "100%", height: "100%", 
              objectFit: "cover"
            //  objectFit:"contain"
              }}
             className="img"
             />
           </div>
           <div className="contentBx">
            <h2>{val?.category}</h2>
          
              <div className="size">
              {/* <b>{val?.resell_price ? fcINR(val.resell_price) : "NA"}</b> */}
              <b>{val?.resell_price}</b>
             </div>

             <div
               className="color mt-10"
               style={{
                 color: "gray",
                 fontSize: 10,
               }}
             >
               <div className="px-2">
                 <p>
                   <SpeedIcon />
                 </p>
                 Top speed
                 <p
                   style={{
                     fontSize: 12,
                     color: "black",
                     fontWeight: 500,
                   }}
                 >
                   {val?.top_speed}
                 </p>
               </div>

               <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                 <p>
                   <BatteryChargingFullIcon />
                 </p>
                 Battery
                 <p
                   style={{
                     fontSize: 12,
                     color: "black",
                     fontWeight: 500,
                   }}
                 >
                   {val?.batter}
                 </p>
               </div>

               <div>
                 <p>
                   <img src={Range} alt="" />
                 </p>
                 Range
                 <p
                   style={{
                     fontSize: 12,
                     color: "black",
                     fontWeight: 500,
                   }}
                 >
                   {val?.range}
                </p>
              </div>

              {/* Electric Service */}

              <div className="px-3">
                <p>
                   <img src={ElectricService} alt="" />
                 </p>
                 Charging Time
                 <p
                   style={{
                     fontSize: 12,
                     color: "black",
                     fontWeight: 500,
                   }}
                 >
                   {val?.top_speed}
                 </p>
               </div>
             </div>          
             <button
               className="more-info-btn"
               onClick={(event) => {
                 // moreInfo(keyNumber);
             // event.stopPropagation();
                 onPress();
                 onClick();
               }}
            >
               More Info
             </button>
               {/* <div className="buy-btn1">
               
             {!cartProduct ? (
               <button className="buy-btn" onClick={handleAddbtn}>
                 Buy Now
               </button>
             ) : (
               <div className="quantity-control">
                 <button className="remove-btn" onClick={handleRemovebtn}>
                   -
                 </button>
                 <span className="quantity">{quantity}</span>
                 <button className="add-btn" onClick={handleAddbtn}>
                   +
                 </button>
               </div>
           )}
           </div>            */}
           </div>
         </div>
       </div>
      ) : (
         null 
      )}

       {/* {showCard && <Card data1={val}/>} */}
     </>
    
  );
}; 

export default ProductCard;
