import Footer from "../Components/UI/Footer";
import ProductCard from "../Components/UI/ProductCard";
// import viewMore from "../assets/viewMore.svg";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CropDinIcon from '@mui/icons-material/CropDin';

import HeroSecondary from "../Components/UI/HeroSecondary";
import HeroHead from "../Components/UI/HeroHead";
import Search from "../Components/UI/search";
import EBike1 from "../assets/E-Bike1.svg";
import ERiksha from "../assets/green 1.svg";
import ELoader from "../assets/e-riksha.svg";
import { Container, Grid, Button,Pagination } from "@mui/material";
import { fetchImage } from "../Api/index";
import { fetchData } from "../Api/index";
import { useState, useEffect } from "react";
import ProductInfoModal from "../Components/UI/ProductInfoModal";
import { Link } from "react-router-dom";
// import { Category } from "../Constants/General";
import BrandList from "../Components/UI/Brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight
 
} from "@fortawesome/free-solid-svg-icons";

export default function Home() {
  const [arr, setArr] = useState([]);
  // const [newArray, setNewArray] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9); // Items per page can be adjusted


  const [childState, setChildState] = useState("");
  const [select, setSelect] = useState("");
  const [cardData, setCardData] = useState({});
  
  const handleChildState = (stateValue) => {
    console.log(stateValue);
    setChildState(stateValue);
  };

  const handleSelectState = (SelectedValue) => {
    console.log(SelectedValue);
    setSelect(SelectedValue);
  };

  const handleData = async () => {
    try {
      const {data} = await fetchData();
        // console.log(data?.result?.[0]?.data);
      setArr(data?.result?.[0]?.data || []);
    } catch (error) {
      
    }
  };


  // const imageOnlyProducts = arr.filter(
  //   (item) => 
  //     item.vehicle_images && 
  //     item.vehicle_images.length > 0 &&
  //     (!item.images || !item.images.some((url) => url.includes("data:video/mp4")))
  // );


  // console.log("test data", imageOnlyProducts );

  // console.log("test", arr);
  
  
  
  useEffect(() => {
    handleData()
  }, []);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = arr.slice(indexOfFirstItem, indexOfLastItem);
  //  console.log(currentItems);


  const borkenImageProductIds = ["65955f9dc045269be6c115e4", "65955fa1c045269be6c1169a","662cd891b14243c40023920e","65955f8fc045269be6c11312","65955f8fc045269be6c11308","65955fa2c045269be6c116e2"];

  const filterNonBrokenImageArray = arr?.filter(item => !borkenImageProductIds?.includes(item?._id));
   
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const [view, setView] = useState('list');
  const[isactive,setIsActive]=useState(true);

  const handleChange = (event, nextView) => {
    setView(nextView);
    setIsActive(prev => !prev);
  };

  return (
    <>
      <Container>
        <ProductInfoModal
          isOpen={isModalOpen}
          onPress={() => setIsModalOpen((prev) => !prev)}
          data={cardData}
        />
        <HeroHead />
        <HeroSecondary />

        <Search
          onChildComp={handleChildState}
          onSelectValue={handleSelectState}
        />
      </Container>
     
      <Container className="my-50">
        <Grid container className={isactive ? "Hero-Product":"Hero-product1"}>
          <Grid item xs={12} className="togglebtn" style={{marginLeft:"15rem",marginBottom:"1rem"}}>
            {/* <p className="category-heading text-center mt-25 mb-2">
              Top Products
            </p> */}
             <ToggleButtonGroup
      orientation="horizontal"
      value={view}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="list" aria-label="list">
        <CropDinIcon />
      </ToggleButton>
      <ToggleButton value="module" aria-label="module">
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
          </Grid>
        
          {/* {imageOnlyProducts.map((val, index) => {
    return (
      <ProductCard
        val={val}
        key={index}
        keyNumber={index}
        onPress={() => setIsModalOpen((prev) => !prev)}
        onClick={() => setCardData(val)}
      />
    );
})} */}
         {/*  <div className={`container-prod`}>
            <div className="more-card"><FontAwesomeIcon icon={faAngleRight} className="more-info-btn" />View More</div>
            <br></br>
            
          </div> */}
            
{filterNonBrokenImageArray?.map((val, index) => {
            return (
              <ProductCard
                val={val}
                key={index}
                keyNumber={index}
                onPress={() => setIsModalOpen((prev) => !prev)}
                onClick={() => setCardData(val)}
                isactive={isactive}
              />
            );
          })}
        </Grid>
       <br/>
       <br />
      
        {/* Brand */}
      </Container>

      {/* <Container>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <p className="category-heading text-center">Choose a category</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              // to="/category"
              // state={{ category: Category.E_SCOOTY }}
              className="linkTag"
            >
              <div className="category-card">
                <img src={EBike1} />
              </div>
              <p className="category-car-title">E-Bike</p>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              // to="/category"
              // state={{ category: Category.E_RICK }}
              className="linkTag"
            >
              <div className="category-card">
                <img src={ERiksha} />
              </div>
              <p className="category-car-title">E-Risksha</p>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              // to="/category"
              // state={{ category: Category.E_LOADER }}
              className="linkTag"
            >
              <div className="category-card">
                <img src={ELoader} />
              </div>
              <p className="category-car-title">E-Loader</p>
            </Link>
          </Grid>
        </Grid>
      </Container> */}

      {/* <Container>
        <Grid item xs={12}>
          <p className="brand-title  mt-35 mb-5 text-center">
            Find By Brand
          </p>
        </Grid>
        <BrandList />
      </Container> */}

      {/* footer */}
      <Footer />
    </>
  );
}

