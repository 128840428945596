import React, { useEffect, useState } from "react";
import green from '../../assets/green 1.svg'
// import Ebike from '../../assets/e-bike.svg'
import ERiksha from '../../assets/e-riksha.svg'
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import andaaz from "../../assets/andaaz.png"
import yatri from "../../assets/yatri.png"
import citylife from "../../assets/citylife.png"
import panther from "../../assets/panther.png"
import badshah from "../../assets/badshah.png"
import allfine from "../../assets/allfine.png"
import arzoo from "../../assets/arzoo.png"
import vandebharat from "../../assets/vandebharat.png"
import jezza from "../../assets/jezza.png"
import ele from "../../assets/ele.png"
import thukral from "../../assets/thukral.png"
import hooghly from "../../assets/hooghly.png"
import bahubali from "../../assets/bahubali.png"
import nova from "../../assets/nova.png"
import movestone from "../../assets/movestone.png"
import speego from "../../assets/speego.png"
import unique from "../../assets/unique.png"
import sahyatri from "../../assets/sahyatri.png"
import bvr from "../../assets/bvr.png"
import pilot from "../../assets/pilot.png"
import dabang from "../../assets/dabang.png"
import ashma from "../../assets/ashma.png"
import tomtom from "../../assets/tomtom.png"
import khushi from "../../assets/khushi.png"
import rutba from "../../assets/rutba.png"
import savari from "../../assets/savari.png"
import srd from "../../assets/srd.png"
import Saarthi from "../../assets/Saarthi.png"
import zeofine from "../../assets/zeofine.png"
import citymetro from "../../assets/citymetro.png"

export default function Example() {
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showNavigation, setShowNavigation] = useState(true);
  const [animationConfig, setAnimationConfig] = useState(config.gentle);

  const ImageComponent = (file) => {
    return <img src={file} className="hero-carousel-Image"  alt="1" height={800} width={800} />;
  };

  const slides = [
    {
      key: 1231,
      content: ImageComponent(
        green
      ),
    },
    {
      key: 12377,
      content: ImageComponent(
        "https://www.electroride.in/image/cache/catalog/Mayuri/0X2A7526-PhotoRoom.png-PhotoRoom%20(1)-800x800.png"
      ),
    },
    {
      key: 1236,
      content: ImageComponent(
        ERiksha
      ),
    },
    {
      key: 1238,
      content: ImageComponent(
        "https://images.jdmagicbox.com/quickquotes/images_main/mini-metro-three-wheelers-12-01-2021-20-220010328-9xcg9.png"
      ),
    },
    {
      key: 1230,
      content: ImageComponent(
        "https://www.electroride.in/image/cache/catalog/Mayuri/BLUE-800x800.png"
      ),
    },
    {
      key: 1240,
      content: ImageComponent(
        "https://5.imimg.com/data5/SELLER/Default/2024/4/412839532/HH/VW/DR/224780/mini-metro-m1-ms-battery-operated-e-rickshaw-250x250.png"
      ),
    },
    {
      key: 1257,
      content: ImageComponent(
        "https://khalsaev.com/wp-content/uploads/2023/06/pro-plus-1.png"
      ),
    },
    {
      key: 1245,
      content: ImageComponent(
        "https://5.imimg.com/data5/HM/XD/PH/GLADMIN-57844/terra-e-rickshaw-250x250.png"
      ),
    },
    {
      key: 1248,
      content: ImageComponent(
        "https://5.imimg.com/data5/SELLER/Default/2024/3/400938566/ZQ/CF/OJ/98138605/sargam-e-ride-model-blue-e-rickshaw-500x500.png"
      ),
    },
    {
      key: 1249,
      content: ImageComponent(
        "https://content.jdmagicbox.com/quickquotes/images_main/e-rickshaw-ultra-4-seater-electric-272604427-wwg52.png?impolicy=queryparam&im=Resize=(360,360),aspect=fit"
      ),
    },
    {
      key: 1253,
      content: ImageComponent(
        "https://indowagen.com/wp-content/uploads/2019/05/9.png"
      ),
    },
    {
      key: 1259,
      content: ImageComponent(
        "https://www.vijaysenergyindustries.com/images/products2/image7.png"
      ),
    },
    {
      key: 1263,
      content: ImageComponent(
        "https://jmtvehicles.com/wp-content/uploads/2024/02/image-56.webp"
      ),
    },
    {
      key: 1266,
      content: ImageComponent(
        "https://dabangev.com/wp-content/uploads/2024/01/New-Project-4.webp"
      ),
    },
    {
      key: 1267,
      content: ImageComponent(
        "https://www.lohiaauto.com/images/dx/01.png"
      ),
    },
    {
      key: 1269,
      content: ImageComponent(
        "https://static.wixstatic.com/media/4d98e6_8d88e150e3b5404db706f2a03fcb6d73~mv2.png/v1/fill/w_996,h_663,al_c,q_90,usm_2.00_1.00_0.00,enc_auto/4d98e6_8d88e150e3b5404db706f2a03fcb6d73~mv2.png"
      ),
    },
    {
      key: 1270,
      content: ImageComponent(
        "https://khalsaev.com/wp-content/uploads/2023/08/al-2.png"
      ),
    },
    {
      key: 1268,
      content: ImageComponent(
        "https://gkrickshaw.in/images/slider_1.png"
      ),
    },
    {
      key: 1255,
      content: ImageComponent(
        "https://ceeonindia.co.in/wp-content/uploads/2023/10/orahi.png"
      ),
    },
    {
      key: 1223,
      content: ImageComponent(
        Saarthi
      ),
    },
    {
      key: 1271,
      content: ImageComponent(
        andaaz
      ),
    },
    {
      key: 1273,
      content: ImageComponent(
        yatri
      ),
    },
    {
      key: 1274,
      content: ImageComponent(
        citylife
      ),
    },
    {
      key: 1279,
      content: ImageComponent(
        panther
      ),
    },
    {
      key: 1277,
      content: ImageComponent(
        badshah
      ),
    },
    {
      key: 1281,
      content: ImageComponent(
        allfine
      ),
    },
    {
      key: 1282,
      content: ImageComponent(
        vandebharat
      ),
    },
    {
      key: 1289,
      content: ImageComponent(
        movestone
      ),
    },
    {
      key: 12,
      content: ImageComponent(
        ele
      ),
    },
    {
      key: 1291,
      content: ImageComponent(
        unique
      ),
    },
    {
      key: 1283,
      content: ImageComponent(
        arzoo
      ),
    },
    {
      key: 1284,
      content: ImageComponent(
        jezza
      ),
    },
    {
      key: 1285,
      content: ImageComponent(
        thukral
      ),
    },
    {
      key: 1286,
      content: ImageComponent(
        hooghly
      ),
    },
    {
      key: 1287,
      content: ImageComponent(
        bahubali
      ),
    },
    {
      key: 1288,
      content: ImageComponent(
        nova
      ),
    },
    {
      key: 1290,
      content: ImageComponent(
        speego
      ),
    },
    {
      key: 1291,
      content: ImageComponent(
        sahyatri
      ),
    },
    {
      key: 1292,
      content: ImageComponent(
        bvr
      ),
    },
    {
      key: 1293,
      content: ImageComponent(
        pilot
      ),
    },
    {
      key: 1294,
      content: ImageComponent(
        dabang
      ),
    },
    {
      key: 1295,
      content: ImageComponent(
        ashma
      ),
    },
    {
      key: 1296,
      content: ImageComponent(
        tomtom
      ),
    },
    {
      key: 1297,
      content: ImageComponent(
        khushi
      ),
    },
    {
      key: 1298,
      content: ImageComponent(
        rutba
      ),
    },
    {
      key: 1299,
      content: ImageComponent(
        savari
      ),
    },
    {
      key: 1225,
      content: ImageComponent(
        srd
      ),
    },
    {
      key: 1226,
      content: ImageComponent(
        zeofine
      ),
    },
    {
      key: 1227,
      content: ImageComponent(
        citymetro
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setGoToSlide(goToSlide + 1);
    }, [2000]);
  }, [goToSlide]);

  return (
    <div
    
      className="hero-carousel"
    >
      <Carousel
        slides={slides}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        animationConfig={animationConfig}
      />
    </div>
  );
}
